define("ember-leaflet/helpers/point", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.point = _exports.default = void 0;
  const isFastBoot = typeof FastBoot !== 'undefined';
  /* global L */

  /**
   * Represents a point with x and y coordinates in pixels.
   * More information about its possible options [here](https://leafletjs.com/reference-1.7.1.html#divicon-option).
   *
   * @function icon
   * @param {Number} x the x coordinate
   * @param {Number} y the y coordinate
   * @param {Boolean} [round] if `true`, rounds the x and y values
   * @return {Point}
   */
  const point = isFastBoot ? function () {} : function point(params) {
    return L.point(...params);
  };
  _exports.point = point;
  var _default = (0, _helper.helper)(point);
  _exports.default = _default;
});
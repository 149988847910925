define("@ember/string/helpers/loc", ["exports", "@ember/component/helper", "@ember/string"], function (_exports, _helper, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.loc = loc;
  /**
  
    @module @ember/string
  
    Calls [loc](/api/classes/Ember.String.html#method_loc) with the
    provided string. This is a convenient way to localize text within a template.
    For example:
  
    ```javascript
    Ember.STRINGS = {
      '_welcome_': 'Bonjour'
    };
    ```
  
    ```handlebars
    <div class='message'>
      {{loc '_welcome_'}}
    </div>
    ```
  
    ```html
    <div class='message'>
      Bonjour
    </div>
    ```
  
    See [Ember.String.loc](/api/classes/Ember.String.html#method_loc) for how to
    set up localized string references.
  
    @method loc
    @for Ember.Templates.helpers
    @param {String} str The string to format.
    @see {Ember.String#loc}
    @public
    @deprecated Use a l10n/i18n
  */

  function loc(params /*, hash*/) {
    return (0, _string.loc)(...params);
  }
  var _default = (0, _helper.helper)(loc);
  _exports.default = _default;
});
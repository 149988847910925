define("ember-sweetalert/services/swal", ["exports", "@ember/service", "@ember/application", "@ember/runloop", "rsvp", "sweetalert2"], function (_exports, _service, _application, _runloop, _rsvp, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  class SweetAlertService extends _service.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "sweetAlert", void 0);
      let config = (0, _application.getOwner)(this).resolveRegistration('config:environment') || {};
      this.sweetAlert = _sweetalert.default.mixin(config['ember-sweetalert'] || {});
    }
    fire() {
      for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }
      return new _rsvp.Promise((resolve, reject) => {
        this.sweetAlert.fire(...args).then(resolve, reject);
      });
    }
    isVisible() {
      return this.sweetAlert.isVisible();
    }
    mixin(params) {
      return this.sweetAlert.mixin(params);
    }
    update(params) {
      return this.sweetAlert.update(params);
    }
    close() {
      this._run('close');
    }
    getContainer() {
      return this.sweetAlert.getContainer();
    }
    getHeader() {
      return this.sweetAlert.getHeader();
    }
    getTitle() {
      return this.sweetAlert.getTitle();
    }
    getProgressSteps() {
      return this.sweetAlert.getProgressSteps();
    }
    getCloseButton() {
      return this.sweetAlert.getCloseButton();
    }
    getContent() {
      return this.sweetAlert.getContent();
    }
    getImage() {
      return this.sweetAlert.getImage();
    }
    getActions() {
      return this.sweetAlert.getActions();
    }
    getFooter() {
      return this.sweetAlert.getFooter();
    }
    getFocusableElements() {
      return this.sweetAlert.getFocusableElements();
    }
    getConfirmButton() {
      return this.sweetAlert.getConfirmButton();
    }
    getDenyButton() {
      return this.sweetAlert.getDenyButton();
    }
    getCancelButton() {
      return this.sweetAlert.getCancelButton();
    }
    enableButtons() {
      this._run('enableButtons');
    }
    disableButtons() {
      this._run('disableButtons');
    }
    showLoading() {
      this._run('showLoading');
    }
    hideLoading() {
      this._run('hideLoading');
    }
    isLoading() {
      return this.sweetAlert.isLoading();
    }
    getTimerLeft() {
      return this.sweetAlert.getTimerLeft();
    }
    stopTimer() {
      return this.sweetAlert.stopTimer();
    }
    resumeTimer() {
      return this.sweetAlert.resumeTimer();
    }
    toggleTimer() {
      return this.sweetAlert.toggleTimer();
    }
    isTimerRunning() {
      return this.sweetAlert.isTimerRunning();
    }
    increaseTimer(n) {
      return this.sweetAlert.increaseTimer(n);
    }
    clickConfirm() {
      this._run('clickConfirm');
    }
    clickDeny() {
      this._run('clickDeny');
    }
    clickCancel() {
      this._run('clickCancel');
    }
    getInput() {
      return this.sweetAlert.getInput();
    }
    disableInput() {
      this._run('disableInput');
    }
    enableInput() {
      this._run('enableInput');
    }
    showValidationMessage(error) {
      this._run('showValidationMessage', error);
    }
    resetValidationMessage() {
      this._run('resetValidationMessage');
    }
    getValidationMessage() {
      return this.getValidationMessage();
    }
    queue() {
      this._run('queue', ...arguments);
    }
    getQueueStep() {
      return this.sweetAlert.getQueueStep();
    }
    insertQueueStep() {
      this._run('insertQueueStep', ...arguments);
    }
    deleteQueueStep(index) {
      this._run('deleteQueueStep', index);
    }
    isValidParameter(param) {
      return this.sweetAlert.isValidParameter(param);
    }
    isUpdatableParameter(param) {
      return this.sweetAlert.isUpdatableParameter(param);
    }
    _run(method) {
      for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        args[_key2 - 1] = arguments[_key2];
      }
      (0, _runloop.scheduleOnce)('afterRender', this.sweetAlert, method, ...args);
    }
  }
  _exports.default = SweetAlertService;
});
define("@ember/test/adapter", ["exports", "ember-testing"], function (_exports, _emberTesting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberTesting.Test.Adapter;
  _exports.default = _default;
});

define("ember-leaflet/components/circle-marker-layer", ["exports", "ember-leaflet/components/point-path-layer"], function (_exports, _pointPathLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  /**
   * A circle of a fixed size with radius specified in pixels.
   *
   * @class CircleLayer
   * @extends PointPathLayer
   */
  class CircleMarkerLayer extends _pointPathLayer.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "leafletOptions", [...this.leafletOptions,
      /**
       * Radius of the circle marker, in pixels. Defaults to `10`.
       *
       * @argument radius
       * @type {Number}
       */
      'radius']);
      _defineProperty(this, "leafletDescriptors", [...this.leafletDescriptors, 'radius']);
    }
    createLayer() {
      return this.L.circleMarker(...this.requiredOptions, this.options);
    }
  }
  _exports.default = CircleMarkerLayer;
});
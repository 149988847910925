define("ember-sweetalert/components/sweet-alert", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/service", "@ember/object", "@ember/array"], function (_exports, _component, _templateFactory, _component2, _service, _object, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <span
    aria-hidden="true"
    {{did-insert this.fire this.isOpen}}
    {{did-update this.fire this.isOpen}}
    ...attributes
  ></span>
  
  */
  {
    "id": "VFBS1pc/",
    "block": "[[[11,1],[24,\"aria-hidden\",\"true\"],[17,1],[4,[38,0],[[30,0,[\"fire\"]],[30,0,[\"isOpen\"]]],null],[4,[38,1],[[30,0,[\"fire\"]],[30,0,[\"isOpen\"]]],null],[12],[13],[1,\"\\n\"]],[\"&attrs\"],false,[\"did-insert\",\"did-update\"]]",
    "moduleName": "ember-sweetalert/components/sweet-alert.hbs",
    "isStrictMode": false
  });
  const CONFIGURATION = ['title', 'titleText', 'html', 'text', 'icon', 'iconColor', 'iconHtml', 'showClass', 'hideClass', 'footer', 'backdrop', 'toast', 'target', 'input', 'width', 'padding', 'background', 'position', 'grow', 'customClass', 'timer', 'timerProgressBar', 'animation', 'heightAuto', 'allowOutsideClick', 'allowEscapeKey', 'allowEnterKey', 'stopKeydownPropagation', 'keydownListenerCapture', 'showConfirmButton', 'showDenyButton', 'showCancelButton', 'confirmButtonText', 'denyButtonText', 'cancelButtonText', 'confirmButtonColor', 'denyButtonColor', 'cancelButtonColor', 'confirmButtonAriaLabel', 'denyButtonAriaLabel', 'cancelButtonAriaLabel', 'buttonsStyling', 'reverseButtons', 'focusConfirm', 'focusDeny', 'focusCancel', 'showCloseButton', 'closeButtonHtml', 'closeButtonAriaLabel', 'loaderHtml', 'showLoaderOnConfirm', 'showLoaderOnDeny', 'scrollbarPadding', 'preConfirm', 'preDeny', 'returnInputValueOnDeny', 'imageUrl', 'imageWidth', 'imageHeight', 'imageAlt', 'imageLabel', 'inputPlaceholder', 'inputValue', 'inputOptions', 'inputAutoTrim', 'inputAttributes', 'inputValidator', 'validationMessage', 'progressSteps', 'currentProgressStep', 'progressStepsDistance'];
  const EVENTS = ['willOpen', 'onBeforeOpen',
  // deprecated, use willOpen
  'didOpen', 'onOpen',
  // deprecated, use didOpen
  'didRender', 'onRender',
  // deprecated, use didRender
  'willClose', 'onClose',
  // deprecated, use willClose
  'didClose', 'onAfterClose',
  // deprecated, use didClose,
  'didDestroy', 'onDestroy' // deprecated, use didDestroy
  ];
  let SweetAlertComponent = (_class = class SweetAlertComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "swal", _descriptor, this);
    }
    get isOpen() {
      if (undefined === this.args.show) {
        return true;
      }
      return this.args.show;
    }
    async fire(element, _ref2) {
      let [open] = _ref2;
      if (open) {
        let result = await this.swal.fire(this._values());
        if (result.value) {
          this._call('onConfirm', result);
          return;
        }
        this._call('onCancel', result);
      }
    }
    _call(method) {
      if (!this.isDestroying && this.args[method]) {
        for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }
        this.args[method](...args);
      }
    }
    _values() {
      let props = {};
      (0, _array.A)(CONFIGURATION).forEach(key => {
        let value = this.args[key];
        if (undefined !== value) {
          props[key] = value;
        }
      });
      (0, _array.A)(EVENTS).forEach(key => {
        if (undefined !== this.args[key]) {
          props[key] = () => this._call(key, ...arguments);
        }
      });
      return props;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "swal", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "fire", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "fire"), _class.prototype)), _class);
  _exports.default = SweetAlertComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, SweetAlertComponent);
});
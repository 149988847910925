define("ember-leaflet/components/video-layer", ["exports", "ember-leaflet/components/image-layer"], function (_exports, _imageLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
  function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
  /**
   * Used to load and display a video player over specific bounds of the map.
   * A video overlay uses the <video> HTML5 element.
   *
   * @class VideoLayer
   * @extends ImageLayer
   */
  class VideoLayer extends _imageLayer.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "leafletRequiredOptions", [...this.leafletRequiredOptions,
      /**
       * The URL of the video (or array of URLs, or even a video element) and the geographical bounds it is tied to.
       *
       * @argument video
       * @type {String|Array|HTMLVideoElement}
       */
      'video',
      /**
       * The geographical bounds the video is tied to.
       *
       * @argument bounds
       * @type {LatLngBounds}
       */
      'bounds']);
      _defineProperty(this, "leafletOptions", [...this.leafletOptions,
      /**
       * Whether the video starts playing automatically when loaded.
       * Defaults to `true`.
       *
       * @argument autoplay
       * @type {Boolean}
       */
      'autoplay',
      /**
       * Whether the video will loop back to the beginning when played.
       * Defaults to `true`.
       *
       * @argument loop
       * @type {Boolean}
       */
      'loop',
      /**
       * Whether the video will save aspect ratio after the projection.
       * Relevant for supported browsers. See browser compatibility
       * Defaults to `true`.
       *
       * @argument keepAspectRatio
       * @type {Boolean}
       */
      'keepAspectRatio',
      /**
       * Whether the video starts on mute when loaded.
       * Defaults to `false`.
       *
       * @argument muted
       * @type {Boolean}
       */
      'muted',
      /**
       * Mobile browsers will play the video right where it is instead of open it up in fullscreen mode.
       * Defaults to `true`.
       *
       * @argument playsInline
       * @type {Boolean}
       */
      'playsInline',
      /**
       * When true, a mouse event on this layer will trigger the same event on the map.
       * Defaults to `true`.
       *
       * @argument bubblingMouseEvents
       * @type {Boolean}
       */
      'bubblingMouseEvents']);
      _defineProperty(this, "leafletDescriptors", [...this.leafletDescriptors, 'url', 'opacity', 'bounds']);
    }
    createLayer() {
      return this.L.videoOverlay(...this.requiredOptions, this.options);
    }
  }
  _exports.default = VideoLayer;
});
define("ember-composability-tools/index", ["exports", "ember-composability-tools/components/node", "ember-composability-tools/components/root"], function (_exports, _node, _root) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "Node", {
    enumerable: true,
    get: function () {
      return _node.default;
    }
  });
  Object.defineProperty(_exports, "Root", {
    enumerable: true,
    get: function () {
      return _root.default;
    }
  });
});